var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.contentTagText)?_c('div',{staticClass:"ContentTag mt-1 mb-1 mr-2 d-inline-block text-center text-capitalize",class:[
    _vm.fontSmaller && 'font-smaller',
    _vm.fontWeightBold && 'font-weight-bold',
    _vm.useGrayBackgroundColor && 'ContentTag__container--color-gray',
    _vm.contentTagBackgroundColor,
    _vm.contentTagForegroundColor,
    _vm.usePillShape,
  ]},[_vm._v("\n  "+_vm._s(_vm.contentTagText)+"\n")]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }