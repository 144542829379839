<template>
  <OverviewCard :item="item" @click="showCommunity" />
</template>

<script>
import OverviewCard from "~/components/communities/components/OverviewCard.vue";

export default {
  name: "CommunityOverview",
  components: {
    OverviewCard,
  },
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    showCommunity() {
      this.$router.push({
        path: this.item.exploreLink,
      });
    },
  },
};
</script>
