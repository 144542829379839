<template>
  <DepartmentOverviewCard :item="item" @click="showDepartment" />
</template>

<script>
import DepartmentOverviewCard from "~/components/page/institution/DepartmentOverviewCard.vue";

export default {
  name: "DepartmentOverview",
  components: {
    DepartmentOverviewCard,
  },
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    showDepartment() {
      this.$router.push({
        path: this.item.exploreLink,
      });
    },
  },
};
</script>
