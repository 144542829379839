<template>
  <v-row ref="itemsParent" class="layout-items" mb-5>
    <v-col
      v-for="item in items"
      :key="item.id"
      :cols="cols"
      :sm="sm"
      :md="md"
      :lg="lg"
    >
      <component
        :is="componentName"
        :item="item"
        :show-content-type="showContentType"
        :data-test-id="dataTestId"
      />
    </v-col>
  </v-row>
</template>

<script>
import DepartmentOverview from "../page/institution/DepartmentOverview.vue";
import SubjectSummary from "~/components/groups/components/SubjectSummary";
import CategorySummary from "~/components/groups/components/CategorySummary";
import ArticleSummary from "~/components/articles/ArticleSummary";
import EventSummary from "~/components/events/EventSummary";
import EventSponsorSummary from "~/components/events/EventSponsorSummary";
import CategoryOverview from "~/components/groups/components/CategoryOverview";
import SubjectOverview from "~/components/groups/components/SubjectOverview";
import CommunityOverview from "~/components/communities/CommunityOverview";
import CollectionOverview from "~/components/communities/CollectionOverview";
import ResearchDirectionsQuestionsTile from "~/components/research-directions/ResearchDirectionsQuestionsTile.vue";
import ArticleOverviewInstitution from "~/components/page/institution/ArticleOverviewInstitution";

export default {
  name: "LayoutItems",
  components: {
    SubjectSummary,
    CategorySummary,
    ArticleSummary,
    CategoryOverview,
    SubjectOverview,
    EventSummary,
    EventSponsorSummary,
    CommunityOverview,
    CollectionOverview,
    ResearchDirectionsQuestionsTile,
    ArticleOverviewInstitution,
    DepartmentOverview,
  },
  props: {
    items: { type: Array, required: true },
    componentName: { type: String, required: true },
    showContentType: { type: Boolean, default: false },
    cols: { type: [String, Number], default: 12 },
    sm: { type: [String, Number], default: 6 },
    md: { type: [String, Number], default: 3 },
    lg: { type: [String, Number], default: 2 },
    dataTestId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      CategorySummary: "CategorySummary",
    };
  },
  methods: {
    focusItem(index) {
      this.$refs.itemsParent.children[index].children[0].focus();
    },
  },
};
</script>
