<template>
  <div class="EventOverview">
    <v-div
      v-ripple
      background-subdued
      :class="{
        'elevation-5': showElevatedStyle,
        'rounded-corner': roundedCorner,
      }"
      has-action
      relative
    >
      <v-div class="imageDisplay">
        <img class="thumbnail" :src="imageUrl" alt="" />
      </v-div>
      <v-div background-subdued pa-2 style text-left>
        <v-div
          height-80
          foreground-primary
          border-softened-bottom-thin
          mb-4
          font-weight-bold
          pa-4
        >
          <ClampedTextbox html-element="h3">{{ item.title }}</ClampedTextbox>
        </v-div>
        <v-div v-if="date" font-weight-black pl-4>
          <span class="d-sr-only">, Date:</span>
          {{ date }}
        </v-div>
        <v-div v-else>&nbsp;</v-div>
        <v-div mt-2 mb-2>
          <v-div font-weight-bold d-inline pr-4 pl-4>
            <span class="d-sr-only">,</span>
            {{ item.count }}
          </v-div>
          <v-div foreground-muted d-inline border-mutted-left-thin pl-4>
            Live content
            <span class="d-sr-only">articles,</span>
          </v-div>
        </v-div>
      </v-div>
      <v-div absolute right="4" bottom="4" text-right>
        <SmallPartnerLogo :origin="item.origin" />
      </v-div>
    </v-div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SmallPartnerLogo from "../banner/SmallPartnerLogo.vue";
import localDate from "~/plugins/utilities/localDate";
import getAssetUrl from "~/plugins/utilities/getAssetUrl.js";
import ClampedTextbox from "~/components/groups/components/ClampedTextbox";

export default {
  name: "EventOverview",
  components: {
    SmallPartnerLogo,
    ClampedTextbox,
  },
  props: {
    item: { type: Object, required: true },
    showPublished: { type: Boolean, default: false },
    showMetrics: { type: Boolean, default: true },
    showElevatedStyle: { type: Boolean, default: false },
  },

  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
      partners: (state) => state.theme.partners,
    }),
    partner() {
      return this.$config.partner;
    },
    displayMetric() {
      return "Submissions";
    },
    localDate() {
      return localDate;
    },
    imageUrl() {
      return getAssetUrl(this.item.bannerAsset);
    },
    date() {
      const { startDate, endDate } = this.item;

      if (startDate && endDate) {
        return `${localDate(startDate, "EEE MMM dd")} - ${localDate(
          endDate,
          "EEE MMM dd yyyy"
        )}`;
      }

      if (this.item.startDate) {
        return localDate(startDate, "EEE MMM dd yyyy");
      }

      return null;
    },
    roundedCorner() {
      return this.layout.eventOverviewRoundedCorner;
    },
  },
};
</script>

<style>
.EventOverview {
  position: relative;
}

.EventOverview .rounded-corner {
  border-top-right-radius: 128px;
  overflow: hidden;
}

.EventOverview .imageDisplay {
  cursor: pointer;
}

.EventOverview img {
  width: 100%;
  aspect-ratio: 16 / 10;

  object-fit: contain;
  object-position: 50% 50%;
}

.EventOverview h3 {
  font-size: 16px;
}
</style>
