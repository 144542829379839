<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="abstract"
    :class="[truncateAbstract && 'truncate-abstract', textPadding && 'pa-2']"
    v-html="sanitizedAbstract"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Abstract",
  props: {
    abstract: { type: String, required: true },
    truncateAbstract: { type: Boolean, default: false },
    textPadding: { type: Boolean, default: true },
  },
  computed: {
    sanitizedAbstract() {
      return this.$sanitize(this.abstract, {
        allowedTags: [
          "div",
          "p",
          "br",
          "b",
          "i",
          "u",
          "sup",
          "sub",
          "em",
          "strong",
        ],
        selfClosing: ["br"],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate-abstract {
  display: -webkit-box;
  -webkit-line-clamp: 15;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
