<template>
  <div class="community-overview">
    <article>
      <div class="community-overview__container elevation-3 display-relative">
        <div class="community-overview__image-container">
          <img class="community-overview__image" :src="imageUrl" alt="" />
        </div>
        <div class="pa-2 px-4">
          <div class="community-overview__heading mb-4 primary--text height-30">
            <ClampedTextbox html-element="h3">
              {{ item.name }}
              <span v-if="showResearchDirectionsLabel" class="d-sr-only"
                >(part of Research Directions)</span
              >
            </ClampedTextbox>
          </div>
          <div class="community-overview__description mb-4">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="description"></div>
          </div>
          <v-btn
            :aria-label="buttonAriaLabel"
            class="white--text"
            color="primary"
            @click="onClickExplore"
          >
            Explore
            <span class="d-sr-only">{{ item.name }}</span>
          </v-btn>
          <div class="mt-4 py-4 border-softened border-top border-thin">
            <div class="d-inline pr-4">
              <div class="d-inline font-weight-bold primary--text">
                {{ item.itemsCount }}
              </div>
              Items
            </div>
            <div v-if="showContributors" class="d-inline">
              <div class="d-inline font-weight-bold primary--text">
                {{ item.contributorsCount }}
              </div>
              Contributors
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import ClampedTextbox from "~/components/groups/components/ClampedTextbox";
import getAssetUrl from "~/plugins/utilities/getAssetUrl.js";

export default {
  name: "CommunityOverview",
  components: {
    ClampedTextbox,
  },
  props: {
    item: { type: Object, required: true },
  },

  computed: {
    partner() {
      return this.$config.partner;
    },
    buttonAriaLabel() {
      return `explore ${this.item.name}`;
    },
    description() {
      return this.$sanitize(this.item.description);
    },
    imageUrl() {
      return getAssetUrl(this.item.bannerAsset);
    },
    showResearchDirectionsLabel() {
      return !!this.item.journalId;
    },
    showContributors() {
      return this.item.contributorsCount || this.item.contributorsCount === 0;
    },
  },
  methods: {
    onClickExplore() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.community-overview {
  position: relative;

  &__container {
    border-radius: 5px;
  }

  &__image-container {
    position: relative;
  }

  &__image {
    width: 100%;
    aspect-ratio: 16 / 10;
    object-fit: contain;
    object-position: 50% 50%;
    border-radius: 6px 6px 0 0;
    display: block;
  }

  &__description {
    min-height: 155px;
    font-size: 14px;
  }

  &__heading {
    font-size: 14px;
  }
}
</style>
