<template>
  <div
    v-if="contentTagText"
    class="ContentTag mt-1 mb-1 mr-2 d-inline-block text-center text-capitalize"
    :class="[
      fontSmaller && 'font-smaller',
      fontWeightBold && 'font-weight-bold',
      useGrayBackgroundColor && 'ContentTag__container--color-gray',
      contentTagBackgroundColor,
      contentTagForegroundColor,
      usePillShape,
    ]"
  >
    {{ contentTagText }}
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ContentTag",
  props: {
    tagText: { type: String, default: null },
    article: { type: Object, default: () => ({}) },
    fontSmaller: { type: Boolean, default: true },
    fontWeightBold: { type: Boolean, default: true },
    backgroundColor: { type: String, default: null },
    foregroundColor: { type: String, default: null },
    roundedPill: { type: Boolean, default: false },
    useGrayBackgroundColor: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    contentTagBackgroundColor() {
      if (this.useGrayBackgroundColor) {
        return undefined;
      }
      return this.backgroundColor || this.layout.contentTagBackgroundColor;
    },
    contentTagForegroundColor() {
      if (this.useGrayBackgroundColor) {
        return undefined;
      }
      return `${
        this.foregroundColor || this.layout.contentTagForegroundColor
      }--text`;
    },
    contentTagText() {
      return this.tagText || this.article.contentType?.name || undefined;
    },
    usePillShape() {
      return this.roundedPill ? "px-5 py-2 rounded-pill" : "px-2 py-1 rounded";
    },
  },
};
</script>

<style scoped>
.ContentTag__container--color-gray {
  background-color: #dcdcdc;
  color: #000000;
}
</style>
