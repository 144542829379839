<template>
  <v-card outlined class="article-overview-institution">
    <v-row class="flex-column pa-0 ma-0">
      <div class="article-overview-institution__metrics-container subdued px-5">
        <div class="pt-2 font-small">
          <div class="d-inline">
            <strong>{{ mainCategory }}</strong>
          </div>
        </div>
      </div>
      <v-container class="article-overview-institution__main-content pt-0">
        <v-row>
          <v-col class="pa-5 pb-1">
            <div class="d-inline-block font-smaller pt-1">
              <div
                class="article-overview-institution__content-tag py-1 px-3 mb-1 mr-2 subdued"
              >
                {{ contentTagText }}
              </div>
            </div>

            <div class="pt-1">
              <span class="d-sr-only">, Title:</span>
              <h3>
                <ArticleTitle
                  :article-html-title="item.title"
                  class="article-overview-institution__title"
                  truncate-title
                />
              </h3>
            </div>

            <div
              class="article-overview-institution__authors pt-1 text-truncate"
            >
              <span class="d-sr-only">, Authors:</span>
              {{
                item.authors
                  | sorted("displayOrder")
                  | concatenateValues(["firstName", "lastName"])
              }}
            </div>

            <div class="article-overview-institution__version muted--text pt-1">
              Version {{ item.version }},
              <span>Submitted</span>
              {{ item.submittedDate | localDate }}
            </div>
          </v-col>

          <div
            class="article-overview-institution__alt-metric-icon-container ma-5"
          >
            <img
              class="article-overview-institution__alt-metric-icon"
              :src="images.altMetricLogo"
              alt="altmetric icon"
            />
          </div>
        </v-row>

        <v-row>
          <v-col class="px-5 py-0">
            <ToggleSection
              class="article-overview-institution__abstract-toggle primary--text mt-1"
              section-name="abstract"
            >
              <div>
                <Abstract
                  class="article-overview-institution__abstract black--text mt-2"
                  :abstract="item.abstract"
                  truncate-abstract
                  :text-padding="false"
                />
              </div>
            </ToggleSection>
            <v-btn class="mt-3" outlined color="primary">
              View Item
              <span class="d-sr-only">, {{ item.title }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-spacer></v-spacer>

      <div class="article-overview-institution__metrics-container subdued px-5">
        <div class="pt-2 font-small">
          <span class="d-sr-only">,</span>
          <div class="d-inline">
            <strong>{{
              item.metrics
                | extractMetricsValue(displayMetric)
                | localNumberWithZero
            }}</strong>
          </div>
          <div class="d-inline border-right border-thin border-muted pr-2">
            {{ displayMetric | extractMetricsName }}
          </div>

          <span class="d-sr-only">,</span>
          <div class="d-inline ml-1">
            <strong>{{
              item.metrics | extractMetricsValue("CITATION_COUNT")
            }}</strong>
            Citations
          </div>
        </div>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

import ArticleTitle from "~/components/articles/components/ArticleTitle.vue";
import ToggleSection from "~/components/page/components/buttons/ToggleSection.vue";
import Abstract from "~/components/articles/components/Abstract";

export default {
  name: "ArticleOverviewInstitution",
  components: {
    ArticleTitle,
    ToggleSection,
    Abstract,
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
      images: (state) => state.theme.images,
    }),
    displayMetric() {
      return this.layout.articleSummaryDisplayMetric;
    },
    contentTagText() {
      return this.item.contentType?.name;
    },
    mainCategory() {
      return this.item.mainCategory?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-overview-institution {
  position: relative;
  border-radius: 8px !important;

  &__main-content {
    min-height: 250px;

    @media screen and (max-width: 800px) {
      min-height: 270px;
    }
  }

  &__content-tag {
    border-radius: 20px;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }

  &__authors {
    font-size: 14px;
    font-weight: 500;
  }

  &__version {
    font-size: 14px;
  }

  &__abstract-toggle {
    font-size: 12px;
  }

  &__abstract {
    font-size: 14px;
  }

  .v-btn--outlined {
    border-width: 1px !important;
  }

  &__alt-metric-icon-container {
    width: 50px;
    height: 50px;
  }

  &__metrics-container {
    width: 100%;
    height: 38px;
  }

  &__alt-metric-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
