export default function getAssetUrl(bannerAsset) {
  if (bannerAsset === null || !bannerAsset) {
    return null;
  }

  if (bannerAsset?.mediumThumbnail.url) {
    return bannerAsset.mediumThumbnail.url;
  }

  if (bannerAsset?.largeThumbnail.url) {
    return bannerAsset.largeThumbnail.url;
  }

  if (bannerAsset?.smallThumbnail.url) {
    return bannerAsset.smallThumbnail.url;
  }

  return null;
}
