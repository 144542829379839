export default function toCanonicalUrl(url, partner) {
  if (url.startsWith("/")) {
    url = url.slice(1);
  }
  if (partner.toUpperCase() === "APSA") {
    return `https://preprints.apsanet.org/engage/${partner.toLowerCase()}/${url}`;
  }

  if (partner.toUpperCase() === "CHEMRXIV") {
    return `https://chemrxiv.org/engage/${partner.toLowerCase()}/${url}`;
  }

  return `https://www.cambridge.org/engage/${partner.toLowerCase()}/${url}`;
}
