<template>
  <article>
    <nuxt-link
      class="CategoryOverview no-underline"
      :to="`/engage/${partner}/category-dashboard/${item.id}`"
    >
      <v-div
        :height="displayIsMobile ? 208 : displayIsTablet ? 257 : 208"
        elevation="0"
        background-subdued
      >
        <v-div pl-2 pr-2 pt-3 min-height-96>
          <v-div
            float-left
            font-weight-bold
            foreground-primary
            :style="`word-wrap: break-word; max-width: calc(100% - ${pillWidth}px);`"
          >
            <h2>{{ item.name }}</h2>
          </v-div>
          <v-div
            ref="pill"
            float-right
            background-secondary
            foreground-inverted
            radius="16"
            text-center
            height-24
            min-width-48
            pl-1
            pr-1
            ml-1
          >
            <span class="d-sr-only">,</span>
            {{ item.count | localNumber }}
            <span class="d-sr-only">articles,</span>
          </v-div>
        </v-div>
        <v-div pl-2 pr-2 pb-3 height="100% - 96px">
          <ClampedTextbox>{{ item.description }}</ClampedTextbox>
        </v-div>
      </v-div>
    </nuxt-link>
  </article>
</template>

<script>
import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

import ClampedTextbox from "~/components/groups/components/ClampedTextbox";

export default {
  name: "CategoryOverview",
  components: {
    ClampedTextbox,
  },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      pillWidth: 48,
    };
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
  },
  mounted() {
    this.pillWidth = this.$refs.pill.$el.clientWidth + 5;
  },
};
</script>

<style>
.CategoryOverview h2 {
  font-size: 16px;
}
</style>
