<template>
  <a :href="item.url" target="_blank" rel="nofollow">
    <v-div v-ripple class="EventSponsorSummary" height-128 has-action relative>
      <img
        v-if="imageUrl"
        class="sponsor-image"
        :src="imageUrl"
        :alt="`${item.text} homepage`"
      />
      <v-div v-else class="no-image-display" background-primary height-128>
        <span class="d-sr-only">{{ item.text }} homepage</span>
      </v-div>
    </v-div>
  </a>
</template>

<script>
export default {
  name: "EventSponsorSummary",
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    imageUrl() {
      const asset = this.item.asset;
      if (asset === null) {
        return null;
      }

      // go with small by default
      if (asset.smallThumbnail?.url) {
        return asset.smallThumbnail.url;
      } else if (asset.mediumThumbnail?.url) {
        return asset.mediumThumbnail.url;
      } else if (asset.largeThumbnail?.url) {
        return asset.largeThumbnail.url;
      }

      return null;
    },
  },
};
</script>

<style>
.EventSponsorSummary {
  position: relative;
  border: 1px solid #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EventSponsorSummary img.sponsor-image {
  max-height: 128px;
  width: auto;
  height: auto;
}

.EventSponsorSummary .no-image-display {
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
</style>
