import { render, staticRenderFns } from "./SmallPartnerLogo.vue?vue&type=template&id=101a0036&"
import script from "./SmallPartnerLogo.vue?vue&type=script&lang=js&"
export * from "./SmallPartnerLogo.vue?vue&type=script&lang=js&"
import style0 from "./SmallPartnerLogo.vue?vue&type=style&index=0&id=101a0036&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports