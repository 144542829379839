<template>
  <v-div ref="outer" class="ClampedTextBox" overflow-hidden relative>
    <component :is="htmlElement">
      <slot />
      <v-div v-if="isOverflowing" class="more-text" background-subdued
        >...</v-div
      >
    </component>
  </v-div>
</template>

<script>
export default {
  name: "ClampedTextBox",
  props: {
    htmlElement: { type: String, default: "v-div" },
  },

  data() {
    return {
      isOverflowing: false,
      text: "",
    };
  },
  mounted() {
    const element = this.$refs.outer.$el;
    this.isOverflowing = element.scrollHeight > element.clientHeight;
    this.text = this.$slots.default
      .map((node) => node.text || (node.elm && node.elm.textContent))
      .join(" ");
  },
};
</script>

<style>
.ClampedTextBox {
  height: 100%;
  width: 100%;
  word-break: normal;
  overflow-wrap: break-word;
}

.ClampedTextBox .more-text {
  position: absolute;
  padding-left: 4px;
  bottom: 0;
  right: 0;
  z-index: 3;
  opacity: 0.45;
}
</style>
