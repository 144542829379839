<template>
  <div class="toggle-section">
    <div :class="boldButtonText && 'font-weight-bold'">
      <button
        :aria-expanded="`${showSection}`"
        :class="flexButton && 'toggle-section__button'"
        @click="toggleSection"
      >
        <span class="toggle-section__action-text">{{
          showSection ? `Hide ${sectionName}` : `Show ${sectionName}`
        }}</span>
        <v-icon small>{{ showSection ? mdiChevronUp : mdiChevronDown }}</v-icon>
      </button>
    </div>
    <div v-if="showSection">
      <slot></slot>
    </div>
    <v-divider v-if="showDivider" class="mt-2 mb-2" />
  </div>
</template>

<script>
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

export default {
  name: "ToggleSection",
  props: {
    sectionName: { type: String, default: "" },
    boldButtonText: { type: Boolean, default: false },
    showDivider: { type: Boolean, default: false },
    flexButton: { type: Boolean, default: false },
  },
  data() {
    return {
      showSection: false,
      // Icons
      mdiChevronDown,
      mdiChevronUp,
    };
  },
  methods: {
    toggleSection() {
      this.showSection = !this.showSection;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-section {
  &__action-text {
    vertical-align: top;
  }
  &__button {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
