<template>
  <OverviewCard :item="item" @click="showContentTab" />
</template>

<script>
import { mapState } from "vuex";
import OverviewCard from "~/components/communities/components/OverviewCard.vue";

export default {
  name: "CollectionOverview",
  components: {
    OverviewCard,
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapState({
      community: (state) => state.selectedCommunity,
    }),
  },
  methods: {
    showContentTab() {
      this.$store.dispatch("selectCommunityContentFilter", {
        collection: this.item,
      });
      this.$store.dispatch("loadCommunityContent", {
        params: { id: this.communityId },
      });
      this.$vuetify.goTo(0);
      this.$router.push({
        path: `/engage/${this.$config.partner}/community-details/${this.community.id}?show=content`,
      });
      this.$root.$emit("set-focus-to-page-start");
    },
  },
};
</script>
