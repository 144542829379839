<template>
  <li class="AuthorsTag d-inline-block">
    <nuxt-link
      :to="`/engage/${
        $config.partner
      }/search-dashboard?authors=${encodeURIComponent(displayName)}`"
      class="underline font-weight-bold"
      data-test-id="AuthorNameLink"
      >{{ displayName }}</nuxt-link
    >
    <v-tooltip v-if="hasOrcid" bottom max-width="250">
      <template #activator="{ on, attrs }">
        <a :href="orcidUrl" target="_blank" v-bind="attrs" v-on="on"
          ><img :src="orcidLogo" :alt="`Author ORCID: ${orcidTooltipText}`"
        /></a>
      </template>
      <span>
        {{ orcidTooltipText }}
      </span>
    </v-tooltip>
    <span class="nudge-left d-inline-block mr-2">{{
      isLast === false ? "," : ""
    }}</span>
  </li>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "~/store/manage/utilities/createItemForMutation";

export default {
  name: "AuthorsTag",
  props: {
    author: { type: Object, required: true },
    index: { type: Number, required: true },
    isLast: { type: Boolean, required: true },
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
    }),
    displayName() {
      const { author } = this;
      if (author.fullName) return author.fullName;
      return `${author.firstName} ${author.lastName}`;
    },
    hasOrcid() {
      const { author } = this;

      return !isEmpty(author.orcid);
    },
    orcidLogo() {
      return this.theme.images.orcidLogo;
    },
    orcidUrl() {
      return `https://orcid.org/${this.author.orcid}`;
    },
    orcidTooltipText() {
      return `We display the ORCID iD icon alongside authors names on our website to acknowledge that the ORCiD has been authenticated when entered by the user. To view the users ORCiD record click the icon. [opens in a new tab]`;
    },
  },
  methods: {
    onGotoOrcid() {
      window.open(this.orcidUrl, "_blank");
    },
  },
};
</script>

<style>
.AuthorsTag .nudge-left {
  margin-left: -3px;
}
</style>
