<template>
  <div class="department-overview">
    <div class="department-overview__container elevation-3 display-relative">
      <div class="department-overview__image-container">
        <img class="department-overview__image" :src="imageUrl" alt="" />
      </div>
      <div
        class="d-flex flex-column justify-space-between py-6 px-5 px-md-8 min-height-256"
      >
        <div>
          <h2 class="department-overview__title mb-6">
            {{ item.name }}
          </h2>
          <div class="department-overview__description mb-6">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="description"></div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <v-btn
            :aria-label="exploreButtonAriaLabel"
            outlined
            color="primary"
            @click="onClickExplore"
          >
            {{ exploreButtonText }}
            <span class="d-sr-only">{{ item.name }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import getAssetUrl from "~/plugins/utilities/getAssetUrl.js";

export default {
  name: "DepartmentOverview",
  props: {
    item: { type: Object, required: true },
  },

  computed: {
    ...mapState({
      theme: (state) => state.theme,
    }),
    partner() {
      return this.$config.partner;
    },
    description() {
      return this.$sanitize(this.item.description);
    },
    imageUrl() {
      return getAssetUrl(this.item.bannerAsset);
    },

    exploreButtonText() {
      return `Explore department content`;
    },
    exploreButtonAriaLabel() {
      return `${this.exploreButtonText} -  ${this.item.name}`;
    },
  },
  methods: {
    onClickExplore() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.department-overview {
  position: relative;

  &__title {
    line-height: 26px;
    font-size: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__container {
    border-radius: 8px;
    border-bottom: 13px solid rgba(46, 79, 132, 1);
  }

  &__image-container {
    position: relative;
    border-radius: 4px 4px 0 0;
  }

  &__image {
    width: 100%;
    aspect-ratio: 16 / 10;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 4px 4px 0 0;
    display: block;
  }

  &__description {
    font-size: 14px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .v-btn--outlined {
    border-width: 1px !important;
    opacity: 0.9;
  }
}
</style>
