<template>
  <div class="research-directions-questions-tile elevation-3 rounded-6">
    <v-row>
      <v-col
        v-if="item.imageUrl"
        cols="12"
        lg="4"
        order-lg="2"
        class="py-0 py-lg-8"
      >
        <img
          class="research-directions-questions-tile__image pr-lg-8"
          :src="item.imageUrl"
          alt=""
        />
      </v-col>
      <v-col
        cols="12"
        :lg="item.imageUrl ? '8' : '12'"
        order-lg="1"
        class="py-4 px-7 py-lg-8 px-lg-11"
      >
        <ContentTag
          class="mb-4"
          tag-text="Question"
          :font-smaller="false"
        ></ContentTag>

        <!-- eslint-disable vue/no-v-html -->
        <h3
          class="research-directions-questions-tile__title font-weight-medium accent--text"
          v-html="item.name"
        />
        <!-- eslint-enable vue/no-v-html -->

        <div class="mt-3">Published online by Cambridge University Press</div>
        <div class="mt-2">
          {{ item.publishedDate | localDate() }}{{ pageRange }}
        </div>

        <ul class="no-bullet d-block">
          <AuthorsTag
            v-for="(author, index) in authors"
            :key="index"
            :author="author"
            :index="index"
            :is-last="index === authors.length - 1"
          />
        </ul>
        <div class="mt-4 mb-3">
          <ToggleSection
            v-if="item.abstract"
            section-name="abstract"
            :bold-button-text="true"
          >
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="research-directions-questions-tile__description pt-4 pt-md-5"
              v-html="item.abstract"
            />
            <!-- eslint-enable vue/no-v-html -->
          </ToggleSection>
        </div>
        <v-btn
          class="research-directions-questions-tile__button primary inverted--text my-2"
          :aria-label="`Explore Engage Content for ${item.name}`"
          @click="showContentTab"
        >
          Explore Engage content
        </v-btn>
        <v-btn
          class="research-directions-questions-tile__button-inverted action--text my-2"
          :aria-label="`Explore Core Content for ${item.name} [opens in a new tab]`"
          @click="goToCoreContent"
        >
          Explore Core Content
        </v-btn>
        <v-btn
          class="research-directions-questions-tile__button-inverted action--text my-2"
          :aria-label="`Read more about ${item.name} on Cambridge Core [opens in a new tab]`"
          @click="goToQuestionLandingPage"
        >
          Read more
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="research-directions-questions-tile__metrics bright mx-0">
      <v-col cols="12" lg="5" class="d-flex px-4 px-lg-5">
        <span>
          Added:
          <span class="accent--text font-weight-bold">{{
            item.publishedDate | localDate("dd/MM/yyyy")
          }}</span>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToggleSection from "~/components/page/components/buttons/ToggleSection.vue";
import ContentTag from "~/components/articles/components/ContentTag.vue";
import AuthorsTag from "~/components/articles/components/AuthorsTag.vue";

export default {
  name: "ResearchDirectionsQuestionsTile",
  components: { ToggleSection, ContentTag, AuthorsTag },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapState({
      communityId: (state) => state.selectedCommunity.id,
    }),
    pageRange() {
      const { firstPage, lastPage } = this.item;
      if (!firstPage || !lastPage) return null;
      return `, pp. ${firstPage} - ${lastPage}`;
    },
    authors() {
      return this.item.authors.map((author) => {
        return { fullName: author };
      });
    },
  },
  methods: {
    showContentTab() {
      this.$store.dispatch("selectCommunityContentFilter", {
        question: this.item,
      });
      this.$store.dispatch("loadCommunityContent", {
        params: { id: this.communityId },
      });
      this.$vuetify.goTo(0);
      this.$router.push({
        path: `/engage/${this.$config.partner}/community-details/${this.communityId}?show=content`,
      });

      this.$root.$emit("set-focus-to-page-start");
    },
    goToQuestionLandingPage() {
      window.open(this.item.coreUrl, "_blank");
    },
    goToCoreContent() {
      window.open(this.item.coreContentUrl, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.research-directions-questions-tile {
  font-size: 14px;

  &__title {
    line-height: 26px;
    font-size: 20px;
  }

  &__description {
    line-height: 20px;
  }

  &__button,
  &__button-inverted {
    min-width: 220px !important;
  }

  &__button-inverted {
    border: solid 1px var(--v-action-base);
    background: var(--v-action-inverted) !important;
  }

  &__image {
    width: 100%;
    display: block;
    object-fit: contain;

    border-radius: 6px 6px 0 0;

    @include media-breakpoint-up("lg") {
      border-radius: 0;
    }
  }

  &__metrics {
    border-radius: 0 0 6px 6px;
  }
}
</style>
