<template>
  <div class="event-summary">
    <article>
      <nuxt-link
        v-if="isInstitution"
        :data-test-id="dataTestId"
        class="no-underline"
        :to="to"
      >
        <EventOverviewInstitution :item="item" />
      </nuxt-link>
      <nuxt-link
        v-else-if="isOriginPartner"
        :data-test-id="dataTestId"
        class="no-underline"
        :to="to"
      >
        <EventOverview
          :item="item"
          :show-published="showPublished"
          :show-metrics="showMetrics"
          :show-elevated-style="showElevatedStyle"
        />
      </nuxt-link>
      <a
        v-else
        class="no-underline"
        :data-test-id="dataTestId"
        :href="to"
        target="_self"
      >
        <EventOverview
          :item="item"
          :show-published="showPublished"
          :show-metrics="showMetrics"
          :show-elevated-style="showElevatedStyle"
        />
      </a>
    </article>
  </div>
</template>

<script>
import { mapState } from "vuex";

import EventOverview from "~/components/events/EventOverview.vue";
import EventOverviewInstitution from "~/components/page/institution/EventOverviewInstitution.vue";

export default {
  name: "EventSummary",
  components: {
    EventOverview,
    EventOverviewInstitution,
  },
  props: {
    item: { type: Object, required: true },
    showPublished: { type: Boolean, default: false },
    showMetrics: { type: Boolean, default: true },
    showElevatedStyle: { type: Boolean, default: false },
    dataTestId: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    ...mapState({
      partners: (state) => state.theme.partners,
      isInstitution: (state) => state.theme.isInstitution,
    }),
    partner() {
      return this.$config.partner;
    },
    isOriginPartner() {
      return this.item.origin?.toLowerCase() === this.partner.toLowerCase();
    },
    to() {
      return this.isOriginPartner
        ? `/engage/${this.partner}/event-details/${this.item.id}`
        : `/engage/${this.item.origin.toLowerCase()}/event-details/${
            this.item.id
          }`;
    },
  },
};
</script>
