<template>
  <v-card class="event-overview" outlined>
    <v-container v-ripple has-action relative class="pa-0">
      <img class="event-overview__thumbnail" :src="imageUrl" alt="" />
      <div class="pa-4 text-left">
        <div class="height-48">
          <h3>
            <span class="event-overview__title">{{ item.title }}</span>
          </h3>
        </div>

        <v-row
          v-if="date"
          class="event-overview__date pa-0 pl-3"
          align="center"
        >
          <img
            class="event-overview__date-icon mr-1"
            src="~/static/images/Calendar.svg"
            alt
          />
          <span class="d-sr-only">, Date:</span>
          {{ date }}
        </v-row>
        <div v-else>&nbsp;</div>
      </div>
    </v-container>

    <div class="event-overview__metrics-container subdued px-5 py-3">
      <v-row class="px-2">
        <div class="d-inline border-right border-thin border-muted">
          <span class="d-sr-only">,</span>
          <div class="pr-2">{{ item.count }}</div>
        </div>
        <div class="pl-2">Live content</div>
        <span class="d-sr-only">articles,</span>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import localDate from "~/plugins/utilities/localDate";
import getAssetUrl from "~/plugins/utilities/getAssetUrl.js";

export default {
  name: "EventOverviewInstitution",
  props: {
    item: { type: Object, required: true },
  },

  computed: {
    imageUrl() {
      return getAssetUrl(this.item.bannerAsset);
    },
    date() {
      const { startDate, endDate } = this.item;

      if (startDate && endDate) {
        return `${localDate(startDate, "EEE MMM dd")} - ${localDate(
          endDate,
          "EEE MMM dd yyyy"
        )}`;
      }

      if (this.item.startDate) {
        return localDate(startDate, "EEE MMM dd yyyy");
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.event-overview {
  position: relative;
  border-radius: 8px !important;

  &__thumbnail {
    width: 100%;
    aspect-ratio: 16 / 8;

    object-fit: contain;
    object-position: 50% 50%;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__date {
    font-size: 16px;
    font-weight: 600;
  }

  &__date-icon {
    width: 16px;
    height: 16px;
  }

  &__metrics-container {
    width: 100%;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
  }
}
</style>
