<template>
  <v-container
    class="ContainerGrid"
    pl-0
    pr-0
    :py-0="noPadding"
    grid-list-md
    fluid
  >
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "ContainerGrid",
  props: { noPadding: { type: Boolean, default: false } },
};
</script>
