<template>
  <v-div
    v-if="displayLogo"
    class="SmallPartnerLogo"
    layer-2
    ml-2
    pl-2
    width-96
    height-32
  >
    <img v-if="smallLogo" :src="smallLogo" :alt="`by ${sourcePartnerName}`" />
    <v-div v-else float-right caption>{{ origin }}</v-div>
  </v-div>
</template>

<script>
import { mapState } from "vuex";
import getSiteName from "~/plugins/utilities/getSiteName.js";

export default {
  name: "SmallPartnerLogo",

  props: {
    origin: { type: String, required: true },
  },
  computed: {
    ...mapState({
      partners: (state) => state.theme.partners,
      siteName: (state) => getSiteName(state.theme),
    }),
    partner() {
      return this.$config.partner;
    },
    sourcePartnerName() {
      const source = this.partners
        ? this.partners[this.origin.toLowerCase()]
        : undefined;

      return source ? source.name : undefined;
    },
    smallLogo() {
      const source = this.partners
        ? this.partners[this.origin.toLowerCase()]
        : undefined;

      return source?.smallLogo || source?.logo || undefined;
    },
    displayLogo() {
      return (
        this.origin?.toLowerCase() === "coe" ||
        this.origin?.toLowerCase() !== this.partner.toLowerCase()
      );
    },
  },
};
</script>

<style>
.SmallPartnerLogo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
