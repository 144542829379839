<template>
  <article>
    <nuxt-link
      class="SubjectSummary no-underline"
      :to="`/engage/${partner}/subject-dashboard/${item.id}`"
    >
      <v-div
        v-ripple
        :border-secondary-left-verythick="borderLeft"
        :radius-right="radiusRight"
        :radius-left="radiusLeft"
        :height="displayIsMobile ? 60 : 90"
        :font-smaller="displayIsMobile"
        background-subdued
        vertical-center-outer
        width="100%"
        has-action
      >
        <v-div
          pl-3
          pr-3
          :mt-2="!centerDescriptions"
          :vertical-center-inner="centerDescriptions"
          >{{ item.name }}</v-div
        >
        <v-div
          :vertical-center-inner="centerCounters"
          :float-right="!centerCounters"
          :mt-2="!centerCounters"
          width="50px"
        >
          <v-div
            :radius="centerCountersRadius"
            background-secondary
            foreground-inverted
            text-center
            mr-2
            pl-1
            pr-1
          >
            <span>{{ item.count | localNumber }}</span>
          </v-div>
        </v-div>
      </v-div>
    </nuxt-link>
  </article>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "SubjectSummary",
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    radiusRight() {
      return this.layout.subjectSummaryRadiusRight;
    },
    radiusLeft() {
      return this.layout.subjectSummaryRadiusLeft;
    },
    borderLeft() {
      return this.layout.subjectSummaryBorderLeft;
    },
    centerDescriptions() {
      return this.layout.subjectSummaryCenterDescriptions;
    },
    centerCounters() {
      return this.layout.subjectSummaryCenterCounters;
    },
    centerCountersRadius() {
      return this.layout.subjectSummaryCenterCountersRadius;
    },
  },
};
</script>
