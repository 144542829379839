<template>
  <v-div
    v-ripple
    class="ArticleOverview"
    :elevation-3="displayElevated"
    :border-primary-left-thick="borderLeft"
    :radius-right="radiusRight"
    :radius-left="radiusLeft"
    :background="articleSummaryBackgroundColor"
    :border-dimer-bottom="displayBottomBorder"
    pa-3
    has-action
    relative
    @click="showLoading = true"
  >
    <div
      v-if="visibleSubject"
      class="font-smaller font-weight-bold text-truncate primary--text"
    >
      <span class="d-sr-only">Subject:</span>
      {{ item.subjectType ? item.subjectType.name : undefined }}
    </div>
    <v-div
      v-if="visibleCategory"
      class="font-smaller font-weight-bold text-truncate primary--text"
    >
      <span class="d-sr-only">Category:</span>
      {{ mainCategoryName }}
    </v-div>

    <div v-if="showContentTag" class="d-inline-block font-smaller pt-1">
      <span class="d-sr-only">,</span>
      <ContentTag :article="item" />
    </div>

    <div v-if="community" class="d-inline-block font-smaller pt-1">
      <span class="d-sr-only">,</span>
      <ContentTag
        :tag-text="community.name"
        background-color="primary"
        foreground-color="white"
      />
    </div>

    <v-div
      :foreground-primary="primaryTitle"
      :font-weight-bold="primaryTitle"
      height-64
      font-smaller
      overflow-hidden
      has-action
      mt-2
    >
      <span class="d-sr-only">, Title:</span>
      <ClampedTextbox html-element="h3">
        <ArticleTitle :article-html-title="item.title" />
      </ClampedTextbox>
    </v-div>
    <v-div
      v-if="item && item.submittedDate && showSubmittedDateOnContentCards"
      font-smaller
    >
      <span>Submitted</span>
      {{ item.submittedDate | localDate }}
    </v-div>
    <v-div
      :border-subdued-bottom="displayDividerSubdued"
      :border-inverted-bottom="!displayDividerSubdued"
    ></v-div>

    <v-div v-if="visibleAuthors" pt-2 font-small font-weight-bold text-truncate>
      <span class="d-sr-only">, Authors:</span>
      {{
        item.authors
          | sorted("displayOrder")
          | concatenateValues(["firstName", "lastName"])
      }}
    </v-div>
    <v-div v-if="showMetrics && displayMetric" font-small mt-2>
      <span class="d-sr-only">,</span>
      <v-div d-inline>{{
        item.metrics | extractMetricsValue(displayMetric) | localNumberWithZero
      }}</v-div>
      <v-div d-inline muted--text>{{
        displayMetric | extractMetricsName
      }}</v-div>
      <span class="d-sr-only">,</span>
      <v-div v-if="displayCitationsCount" d-inline ml-4
        >{{
          item.metrics | extractMetricsValue("CITATION_COUNT")
        }}
        Citations</v-div
      >
    </v-div>
    <v-div v-if="showPublished" caption font-small mt-2 text-right>{{
      item.publishedDate | localDate
    }}</v-div>
    <v-div absolute right="4" bottom="4">
      <SmallPartnerLogo :origin="item.origin" />
    </v-div>
  </v-div>
</template>

<script>
import { mapState } from "vuex";
import SmallPartnerLogo from "../banner/SmallPartnerLogo.vue";
import mapFlags from "~/plugins/utilities/mapFlags";

import ContentTag from "~/components/articles/components/ContentTag.vue";
import ClampedTextbox from "~/components/groups/components/ClampedTextbox";
import ArticleTitle from "~/components/articles/components/ArticleTitle.vue";

export default {
  name: "ArticleOverview",
  components: {
    SmallPartnerLogo,
    ContentTag,
    ClampedTextbox,
    ArticleTitle,
  },
  props: {
    item: { type: Object, required: true },
    showContentType: { type: Boolean, default: false },
    showPublished: { type: Boolean, default: false },
    showMetrics: { type: Boolean, default: true },
  },
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
      partners: (state) => state.theme.partners,
    }),
    ...mapFlags(["showSubmittedDateOnContentCards"]),
    partner() {
      return this.$config.partner;
    },
    displayMetric() {
      return this.layout.articleSummaryDisplayMetric;
    },
    visibleSubject() {
      return this.layout.articleSummaryVisibleSubject;
    },
    visibleCategory() {
      return this.layout.articleSummaryVisibleCategory;
    },
    showContentTag() {
      return this.layout.articleSummaryVisibleContent && this.showContentType;
    },
    visibleAuthors() {
      return this.layout.articleSummaryVisibleAuthors;
    },
    primaryTitle() {
      return this.layout.articleSummaryPrimaryTitle;
    },
    borderLeft() {
      return this.layout.articleSummaryBorderLeft;
    },
    radiusLeft() {
      return this.layout.articleSummaryRadiusLeft;
    },
    radiusRight() {
      return this.layout.articleSummaryRadiusRight;
    },
    articleSummaryBackgroundColor() {
      return this.layout.articleSummaryBackgroundColor;
    },
    displayElevated() {
      return this.layout.articleSummaryDisplayElevated;
    },
    displayDividerSubdued() {
      return this.layout.articleSummaryDividerSubdued;
    },
    displayBottomBorder() {
      return this.layout.articleSummaryBottomBorder;
    },
    mainCategory() {
      return this.item.mainCategory;
    },
    community() {
      return this.item.community;
    },
    mainCategoryName() {
      return this.mainCategory?.name || "";
    },
    displayCitationsCount() {
      return this.item.citationsCount;
    },
  },
};
</script>

<style>
.ArticleOverview {
  position: relative;
}

.ArticleOverview .v-progress-linear {
  position: absolute;
  z-index: 10;
  bottom: -10px;
  width: 94%;
}

.ArticleOverview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ArticleOverview h3 {
  font-size: 14px;
  font-weight: normal;
}
</style>
