<template>
  <article>
    <nuxt-link
      class="CategorySummary no-underline"
      :to="`/engage/${partner}/category-dashboard/${item.id}`"
      :data-test-id="dataTestId"
    >
      <v-div
        v-ripple
        :border-secondary-left-verythick="borderLeft"
        :radius-right="radiusRight"
        :radius-left="radiusLeft"
        :height="displayIsMobile ? 60 : 90"
        :font-smaller="displayIsMobile"
        background-subdued
        vertical-center-outer
        width="100%"
        has-action
      >
        <v-div
          pl-3
          pr-3
          :mt-2="!centerDescriptions"
          :vertical-center-inner="centerDescriptions"
          >{{ item.name }}</v-div
        >
        <v-div
          :vertical-center-inner="centerCounters"
          :float-right="!centerCounters"
          :mt-2="!centerCounters"
          width="50px"
        >
          <v-div
            :radius="centerCountersRadius"
            background-secondary
            foreground-inverted
            text-center
            mr-2
            pl-1
            pr-1
          >
            <span class="d-sr-only">,</span>
            <span>{{ item.count | localNumber }}</span>
            <span class="d-sr-only">articles</span>
          </v-div>
        </v-div>
      </v-div>
    </nuxt-link>
  </article>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "CategorySummary",
  props: {
    item: { type: Object, required: true },
    dataTestId: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    radiusRight() {
      return this.layout.categorySummaryRadiusRight;
    },
    radiusLeft() {
      return this.layout.categorySummaryRadiusLeft;
    },
    borderLeft() {
      return this.layout.categorySummaryBorderLeft;
    },
    centerDescriptions() {
      return this.layout.categorySummaryCenterDescriptions;
    },
    centerCounters() {
      return this.layout.categorySummaryCenterCounters;
    },
    centerCountersRadius() {
      return this.layout.categorySummaryCenterCountersRadius;
    },
  },
};
</script>

<style>
.CategorySummary h3 {
  font-size: 16px;
  font-weight: normal;
}
</style>
