<template>
  <article>
    <nuxt-link
      v-if="isOriginPartner"
      class="ArticleSummary no-underline"
      :to="to"
      :data-test-id="dataTestId"
    >
      <ArticleOverview
        :item="item"
        :show-content-type="showContentType"
        :show-published="showPublished"
        :show-metrics="showMetrics"
      />
    </nuxt-link>
    <a
      v-else
      class="ArticleSummary no-underline"
      :data-test-id="dataTestId"
      :href="to"
      target="_self"
    >
      <ArticleOverview
        :item="item"
        :show-content-type="showContentType"
        :show-published="showPublished"
        :show-metrics="showMetrics"
      />
    </a>
  </article>
</template>

<script>
import { mapState } from "vuex";

import ArticleOverview from "~/components/articles/ArticleOverview.vue";

export default {
  name: "ArticleSummary",
  components: {
    ArticleOverview,
  },
  props: {
    item: { type: Object, required: true },
    showContentType: { type: Boolean, default: false },
    showPublished: { type: Boolean, default: false },
    showMetrics: { type: Boolean, default: true },
    dataTestId: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    ...mapState({
      partners: (state) => state.theme.partners,
    }),
    partner() {
      return this.$config.partner;
    },
    isOriginPartner() {
      return this.item.origin?.toLowerCase() === this.partner.toLowerCase();
    },
    to() {
      return this.isOriginPartner
        ? `/engage/${this.partner}/article-details/${this.item.id}`
        : `/engage/${this.item.origin.toLowerCase()}/article-details/${
            this.item.id
          }`;
    },
  },
};
</script>
